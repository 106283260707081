.multiStepContainer {
    position: relative;
    border: 1px solid #5009B5;
    padding: 2rem;
    margin: 1rem;
    border-radius: .5rem;
    width: 300px;
}

.stepCounter {
    position: absolute;
    top: .5rem;
    right: .5rem;
}
.multiStepButtons {
    margin-top: 1rem;
    display: flex;
    gap: .5rem;
    justify-content: flex-end;
}
.multiStepButtons>button {
    border: none;
    padding: 10px 20px;
    background-color: #5009B5;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}
.multiStepButtons>button:disabled {
    background-color: #888BF1;
    cursor: not-allowed;
}
.domainErrorMsg {
    color: red;
    padding: 5px;
}
.passwordValidationError {
    font-size: 10px;
}
.stepCounter {
    color: #794CFF;
}
.forgotPwLink {
    text-decoration: none;
}
.forgotPwLink:hover {
    text-decoration: underline;
}