.multiFormTitle {
    text-align: center;
    margin: 0;
    margin-bottom: 2rem;
    color: #794CFF;
}
.multiFormElements {
    display: grid;
    gap: 1rem .5rem;
    /* grid-template-columns: auto minmax(auto, 180px); */
}