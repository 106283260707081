.errorIcon {
    color: red;
}
.infoIcon {
    font-size: 20px;
    color: #5009B5;
}
.successIcon {
    font-size: 20px;
    color: green;
}
.messageWrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    place-self: center;
    place-items: center;
    font-size: 16px;
}
.messageWrapper.error {
    color: red;
}